import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getTitleByPath } from "../../global/routes/getTitleByPath";
import routes from "../../global/routes/routes";
import { paths } from "../../global/routes/routesPaths";
import type { RootState } from "../../global/store";
import { getBaseRoute, getBaseRouteNoDashboard } from "./baseRouteFilter";

interface Route {
  title: string;
  route: string;
}

export type Tab = Route & {
  history?: Route[];
};

export interface INavigationState {
  currentTabIndex: number;
  currentPageTitle: string;
  isLogoutModalOpen: boolean;
  isSwitchLocationModalOpen: boolean;
  sideNavWidth: string;
  tabs: Tab[];
  routeHistory: Route[];
  deletedTab: boolean;
}

export const HOME_TAB: Tab = {
  title: "Home",
  route: paths.dashboard,
  history: [],
};

const initialTabs = [HOME_TAB];

const initialState: INavigationState = {
  currentTabIndex: 0,
  currentPageTitle: "",
  isLogoutModalOpen: false,
  isSwitchLocationModalOpen: false,
  sideNavWidth: "240px",
  tabs: initialTabs,
  routeHistory: [],
  deletedTab: false,
};

export const getCurrentOpenTab = (state: INavigationState) => {
  const { currentTabIndex, tabs } = state;
  const isCurrentTabOpen = currentTabIndex < tabs.length;
  const currentOpenTab = isCurrentTabOpen ? tabs[currentTabIndex] : tabs[0];
  return currentOpenTab;
};

const goToExistentTab = (state: INavigationState, index: number) => {
  state.currentTabIndex = index;
};

const isHome = (index: number) => index === 0;

const getLastHistoryRoute = (history?: Route[]) => {
  if (!history?.length) return "";
  return history[history.length - 1].route;
};

const pushRouteToCurrentTabHistory = (
  state: INavigationState,
  route: string
) => {
  const currentOpenTab = getCurrentOpenTab(state);
  const lastRoute = getLastHistoryRoute(currentOpenTab.history);
  if (lastRoute !== currentOpenTab.route) {
    state.tabs[state.currentTabIndex].history = [
      ...state.tabs[state.currentTabIndex].history,
      { route: currentOpenTab.route, title: state.currentPageTitle },
    ];
  }
  state.tabs[state.currentTabIndex].route = route;
  if (state.routeHistory.length) {
    state.routeHistory[state.routeHistory.length - 1].route = route;
  }
};

const updateCurrentTabRouteIfNeeded = (
  state: INavigationState,
  route: string
) => {
  const currentTab = state.tabs[state.currentTabIndex];
  if (currentTab.route !== route) {
    state.tabs[state.currentTabIndex].route = route;
  }
};

const openNewTab = (state: INavigationState, tab: Tab) => {
  state.tabs.push({ ...tab, history: [] });
  goToExistentTab(state, state.tabs.length - 1);
};

const updatedCurrentTabIndex = (
  openTabIndex: number,
  deletedTabIndex: number
) => {
  // prevent returning -1 as current tab index
  if (deletedTabIndex <= openTabIndex && openTabIndex) return openTabIndex - 1;
  return openTabIndex;
};

const removeTab = (state: INavigationState, index: number) => {
  const newSelectedIndex = updatedCurrentTabIndex(state.currentTabIndex, index);
  goToExistentTab(state, newSelectedIndex);
  state.tabs = state.tabs.filter((_tab, tabIndex) => tabIndex !== index);
};

const closeAllTabs = (state: INavigationState) => {
  state.currentTabIndex = 0;
  state.tabs = initialTabs;
};

const ROUTE_HISTORY_COUNT = 10;

export const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    deleteTabs: (state: INavigationState) => {
      closeAllTabs(state);
    },
    deleteTab: (state: INavigationState, action: PayloadAction<number>) => {
      const { payload: toDeleteTabIndex } = action;

      const canCloseTab = !isHome(toDeleteTabIndex);
      if (!canCloseTab) return;

      removeTab(state, toDeleteTabIndex);
      state.deletedTab = true;
    },
    resetNavigationState: (state) => {
      Object.assign(state, initialState);
    },
    closeCurrentTab: (state: INavigationState) => {
      if (isHome(state.currentTabIndex)) return;
      removeTab(state, state.currentTabIndex);
      state.deletedTab = true;
    },
    navigateInternallyTo: (
      state: INavigationState,
      action: PayloadAction<string>
    ) => {
      const { payload: newRoute } = action;
      pushRouteToCurrentTabHistory(state, newRoute);
    },
    openTab: (
      state: INavigationState,
      action: PayloadAction<{
        newTabRoute: string;
        newTabTitle: string;
        currentRoute: string;
      }>
    ) => {
      const { newTabRoute, newTabTitle, currentRoute } = action.payload;
      const baseNewTabRoute = getBaseRoute(newTabRoute);
      const addRouteHistory = () => {
        // prevent duplicated pushes
        const isEqualToLastRoute = () => {
          const lastRoute = state.routeHistory[state.routeHistory.length - 1];
          if (lastRoute?.route === currentRoute) return true;
        };
        if (isEqualToLastRoute()) {
          return;
        }
        state.routeHistory.push({
          title: getTitleByPath(currentRoute),
          route: currentRoute,
        });
      };

      const deleteFirstRouteHistory = () => {
        state.routeHistory.shift();
      };

      const openTab = () => {
        const tabIndex = state.tabs.findIndex(
          (tab) => getBaseRoute(tab.route) === baseNewTabRoute
        );
        const isNewTab = tabIndex === -1;

        if (isNewTab) {
          openNewTab(state, { title: newTabTitle, route: newTabRoute });
          return;
        }
        goToExistentTab(state, tabIndex);
      };

      const updateHistory = () => {
        if (!state.routeHistory) state.routeHistory = [];

        const exceededRouteHistory =
          state.routeHistory.length > ROUTE_HISTORY_COUNT - 1;

        if (exceededRouteHistory) {
          deleteFirstRouteHistory();
        }
        addRouteHistory();
      };

      openTab();
      updateHistory();
    },
    pushToRouteHistory: (
      state: INavigationState,
      action: PayloadAction<Route>
    ) => {
      const lastRoute = state.routeHistory[state.routeHistory.length];
      const isDuplicatedRoute = lastRoute && lastRoute === action.payload;
      if (isDuplicatedRoute) {
        return;
      }

      state.routeHistory = [...state.routeHistory, action.payload];
      // Always keep history of maximum 10 items
      if (state.routeHistory.length > 10) {
        state.routeHistory = state.routeHistory.slice(-10);
      }
    },
    updateCurrentTabIndex: (
      state: INavigationState,
      action: PayloadAction<number>
    ) => {
      goToExistentTab(state, action.payload);
    },
    updateCurrentTabIndexByRoute: (
      state: INavigationState,
      action: PayloadAction<string>
    ) => {
      const route = action.payload;
      const tabIndex = state.tabs.findIndex(
        (tab) =>
          tab.route.startsWith(route) ||
          tab.history.some((pastRoute) => pastRoute.route === route) ||
          route.startsWith(tab.route)
      );
      const isTabOpen = tabIndex > -1;
      if (isTabOpen) {
        goToExistentTab(state, tabIndex);
        updateCurrentTabRouteIfNeeded(state, route);
      }
    },
    updateIsLogoutModalOpen: (
      state: INavigationState,
      { payload: isModalOpen }: PayloadAction<boolean>
    ) => {
      state.isLogoutModalOpen = isModalOpen;
    },
    updateIsSwitchLocationModalOpen: (
      state: INavigationState,
      { payload: isModalOpen }: PayloadAction<boolean>
    ) => {
      state.isSwitchLocationModalOpen = isModalOpen;
    },
    deleteCurrentTabLastRoute: (state: INavigationState) => {
      const currentTab = state.tabs[state.currentTabIndex];
      currentTab.history.pop();
      state.tabs[state.currentTabIndex] = currentTab;
    },
    updateCurrentPageTitle: (
      state: INavigationState,
      action: PayloadAction<string>
    ) => {
      state.currentPageTitle = action.payload;
    },
    updateCurrentTabRoute: (state, action: PayloadAction<string>) => {
      state.tabs[state.currentTabIndex].route = action.payload;
    },
    redirectedAfterDeleteTab: (state) => {
      state.deletedTab = false;
    },
    browserPop: (state, action: PayloadAction<string>) => {
      const route = action.payload;
      const title = getTitleByPath(route);

      // last route is on current tab (needs to redirect current tab)
      const isOnCurrentTab = state.tabs[state.currentTabIndex].title === title;
      if (isOnCurrentTab) {
        state.tabs[state.currentTabIndex].history?.pop();
        return;
      }

      // last route is on different open tab (needs to redirect to open tab)
      let lastTabIndex = -1;
      const openTab = state.tabs.find((tab, index) => {
        if (index === state.currentTabIndex) return undefined;

        // Check open tab current route
        if (tab.route === route || tab.title === title) {
          lastTabIndex = index;
          return true;
        }

        if (index === 0) return;

        // Check open tab route history
        const previousRoute = tab.history.find(
          (historyRoute) =>
            historyRoute.route === route || historyRoute.title === title
        );
        if (previousRoute) {
          lastTabIndex = index;
          return true;
        }
      });

      if (openTab) {
        state.currentTabIndex = lastTabIndex;
        return;
      }

      state.tabs.push({ route, title: getTitleByPath(route), history: [] });
      state.currentTabIndex = state.tabs.length - 1;
    },
    browserPopNew: (state, action: PayloadAction<string>) => {
      const route = action.payload;
      const baseRoute = getBaseRoute(route);
      const title = getTitleByPath(route);

      //1 Get the current tab
      const currentTab = state.tabs[state.currentTabIndex];

      //2 Check if the baseRoute matches the current tab's route or its history
      const isInCurrentTabHistory = currentTab.history.some(
        (historyRoute) => getBaseRoute(historyRoute.route) === baseRoute
      );

      const isNestedRoute = route.startsWith(currentTab.route);

      if (
        currentTab.route === baseRoute ||
        isInCurrentTabHistory ||
        isNestedRoute
      ) {
        //3 If the route is part of the current tab, update the current route
        if (isInCurrentTabHistory && currentTab.history.length > 0) {
          currentTab.history.pop();
        }
        currentTab.route = route;
        return;
      }

      //4 Check if the baseRoute matches another open tab
      const matchingTabIndex = state.tabs.findIndex((tab) => {
        return (
          tab.route === baseRoute ||
          tab.title === title ||
          tab.history.some(
            (historyRoute) =>
              getBaseRoute(historyRoute.route) === baseRoute ||
              historyRoute.title === title
          )
        );
      });

      if (matchingTabIndex !== -1) {
        //5 If a matching tab is found, switch to that tab
        state.currentTabIndex = matchingTabIndex;
        //6 Update the route to match
        state.tabs[matchingTabIndex].route = route;
        return;
      }

      //7 If no match is found, open a new tab
      state.tabs.push({ route, title: getTitleByPath(route), history: [] });
      state.currentTabIndex = state.tabs.length - 1;
    },
    browserPopNoDashboard: (state, action: PayloadAction<string>) => {
      const route = action.payload;
      const baseRoute = getBaseRouteNoDashboard(route);
      const title = getTitleByPath(route);

      //1 Get the current tab
      const currentTab = state.tabs[state.currentTabIndex];

      //2 Check if the baseRoute matches the current tab's route or its history
      const isInCurrentTabHistory = currentTab.history.some(
        (historyRoute) =>
          getBaseRouteNoDashboard(historyRoute.route) === baseRoute
      );

      const isNestedRoute = route.startsWith(currentTab.route);

      if (
        currentTab.route === baseRoute ||
        isInCurrentTabHistory ||
        isNestedRoute
      ) {
        //3 If the route is part of the current tab, update the current route
        if (isInCurrentTabHistory && currentTab.history.length > 0) {
          currentTab.history.pop();
        }
        currentTab.route = route;
        return;
      }

      //4 Check if the baseRoute matches another open tab
      const matchingTabIndex = state.tabs.findIndex((tab) => {
        return (
          tab.route === baseRoute ||
          tab.title === title ||
          tab.history.some(
            (historyRoute) =>
              getBaseRoute(historyRoute.route) === baseRoute ||
              historyRoute.title === title
          )
        );
      });

      if (matchingTabIndex !== -1) {
        //5 If a matching tab is found, switch to that tab
        state.currentTabIndex = matchingTabIndex;
        //6 Update the route to match
        state.tabs[matchingTabIndex].route = route;
        return;
      }

      //7 If no match is found, open a new tab
      state.tabs.push({ route, title: getTitleByPath(route), history: [] });
      state.currentTabIndex = state.tabs.length - 1;
    },
  },
});

export const {
  browserPopNew,
  browserPopNoDashboard,
  closeCurrentTab,
  deleteCurrentTabLastRoute,
  deleteTabs,
  deleteTab,
  navigateInternallyTo,
  openTab,
  redirectedAfterDeleteTab,
  updateCurrentTabIndex,
  updateCurrentTabIndexByRoute,
  updateCurrentPageTitle,
  updateCurrentTabRoute,
  updateIsLogoutModalOpen,
  updateIsSwitchLocationModalOpen,
  pushToRouteHistory,
} = navigationSlice.actions;

export const selectHasDeletedTab = (state: RootState) =>
  state.navigationState.deletedTab;

export const selectIsLogoutModalOpen = (state: RootState) =>
  state.navigationState.isLogoutModalOpen;

export const selectIsSwitchLocationModalOpen = (state: RootState) =>
  state.navigationState.isSwitchLocationModalOpen;

export const selectCurrentTab = (state: RootState) =>
  state.navigationState.tabs[state.navigationState.currentTabIndex];
export const selectCurrentTabIndex = (state: RootState) =>
  state.navigationState.currentTabIndex;

export const selectIsTabOpen = (route: string) => (state: RootState) => {
  return state.navigationState.tabs.some((tab) => tab.route === route);
};
export const selectIsPreviousTabRoute =
  (route: string) => (state: RootState) => {
    const currentOpenTab = getCurrentOpenTab(state.navigationState);
    return currentOpenTab.history?.some(
      (previousRoute) => previousRoute.route === route
    );
  };

export const selectSideNavWidth = (state: RootState) =>
  state.navigationState.sideNavWidth;

export const selectTabs = (state: RootState) => state.navigationState.tabs;

const getLastDifferentRoute = (currentRoute: string, history: Route[]) => {
  const reversedHistory = history.slice().reverse();
  const lastRoute = reversedHistory.find(
    (route) => route.route !== currentRoute
  );
  return lastRoute;
};
export const { resetNavigationState } = navigationSlice.actions;
export const selectCurrentPage = (state: RootState) =>
  state.navigationState.currentPageTitle;

export const selectTabLastPage = (state: RootState): Route => {
  const currentTab =
    state.navigationState.tabs[state.navigationState.currentTabIndex];

  const lastGlobalRoute = getLastDifferentRoute(
    currentTab.route,
    state.navigationState.routeHistory
  ) || {
    route: routes.HOME.ROOT,
    title: "Home",
  };

  const lastDifferentCurrentTabRoute = getLastDifferentRoute(
    currentTab.route,
    currentTab.history
  );
  return currentTab.history?.length
    ? lastDifferentCurrentTabRoute || lastGlobalRoute
    : lastGlobalRoute;
};

export default navigationSlice.reducer;
