import React, { FC } from "react";

import {
  selectHasSearched,
  selectIsLoading,
  selectPageIndex,
  selectPatients,
  selectSelectedPatientId,
  selectTotalPatients,
  updatePageIndex,
} from "../patientSelectorSlice";

import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../global/hooks/useTypedRedux.hook";
import { AppDispatch } from "../../../../../../global/store";
import ImageContainer from "../../../../../../global/components/ImageContainer/ImageContainer";
import ScrollLoader from "../../../../../../global/components/ScrollLoader/ScrollLoader";
import { NoResultsMessage } from "../patientSearch/NoResultsMessage";
import { PatientCard } from "../patientSearch/PatientCard";
import { SecondaryText } from "../../../../../../global/components/SecondaryText/SecondaryText";
import { TPatient } from "../../../../../../global/domains/patients/types/TPatient.type";
import { Spacer } from "./Spacer";
import { SItemWrapper } from "./styles";
import "../searchLoader.css";
import { IPatientResultsProps } from "./types/IPatientResultsProps";
import { ELoaderClassNames } from "./types/ELoaderClassNames";

export const PatientResults: FC<IPatientResultsProps> = ({
  onPatientCardClick,
}) => {
  const dispatch: AppDispatch = useAppDispatch();

  const hasSearched: boolean = useAppSelector(selectHasSearched);
  const isLoading: boolean = useAppSelector(selectIsLoading);
  const patients: TPatient[] = useAppSelector(selectPatients);
  const totalPatients: number = useAppSelector(selectTotalPatients);
  const pageIndex: number = useAppSelector(selectPageIndex);
  const selectedPatientId: string = useAppSelector(selectSelectedPatientId);

  const setPageIndex = (index: number) => dispatch(updatePageIndex(index));

  if (!totalPatients && !isLoading)
    return <Spacer>{hasSearched && <NoResultsMessage />}</Spacer>;

  const renderItem = ({ key, index, style }) => {
    if (!patients[index]) return null;

    return (
      <SItemWrapper key={key} style={style}>
        <PatientCard
          selected={selectedPatientId === patients[index].id}
          patient={patients[index]}
          onClick={onPatientCardClick}
        />
      </SItemWrapper>
    );
  };

  const isItemLoaded = ({ index }) => !!patients[index];

  const loadMoreItems = (): void => {
    if (isLoading) return;

    setPageIndex(pageIndex + 1);
  };

  const TotalResult: JSX.Element = totalPatients ? (
    <SecondaryText>{totalPatients} results</SecondaryText>
  ) : null;

  const loaderClassName = patients?.length
    ? ELoaderClassNames.notEmptyPatientSearch
    : ELoaderClassNames.emptyPatientSearch;
  const Loader: JSX.Element = isLoading ? (
    <ImageContainer marginTop="0" className={loaderClassName} />
  ) : null;
  return (
    <>
      {TotalResult}
      {Loader}
      <ScrollLoader
        batchSize={4}
        isItemLoaded={isItemLoaded}
        itemHeight={120}
        loadedItemsCount={patients.length}
        loadMore={loadMoreItems}
        renderItem={renderItem}
        totalCount={totalPatients}
        pageIndex={pageIndex}
        listHeight={492}
      />
    </>
  );
};
