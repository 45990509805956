import React, { useEffect } from "react";

export const useFetchImage = (
  externalId: string | undefined,
  componentId: number | undefined,
  token: string | undefined
) => {
  const [imgSource, setImgSource] = React.useState<string | undefined>();
  const [imgLoading, setImgLoading] = React.useState<boolean>(false);

  useEffect(() => {
    const fetchImage = async () => {
      setImgLoading(true);
      try {
        const res = await fetch(
          `${process.env.REACT_APP_YAPI_DATAFORMS}/file/${externalId}?sid=${componentId}`,
          {
            method: "GET",
            headers: {
              Authorization: token || "",
              Accept: "image/*",
            },
          }
        );
        if (!res.ok) throw new Error("Failed to fetch image");
        const blob = await res.blob();
        const url = URL.createObjectURL(blob);
        setImgSource(url);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Image fetch error:", error);
      } finally {
        setImgLoading(false);
      }
    };

    if (externalId && token) {
      fetchImage();
    }

    return () => {
      if (imgSource) {
        URL.revokeObjectURL(imgSource);
      }
    };
  }, [externalId, componentId, token]);

  return { imgSource, imgLoading };
};
