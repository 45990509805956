import React, { useEffect } from "react";
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Typography,
  Tooltip,
  Box,
} from "@mui/material";
import { EFormAssignmentNotificationType } from "../../../../../global/enums/EFormAssignmentNotificationType";
import { Controller, useFormContext } from "react-hook-form";
import { IFormRadioButtonsPropTypes } from "./IFormRadioButtonsPropTypes";
import { ErrorHelperText, SRadioButton } from "./styles";

export const FormRadioButtons: React.FC<IFormRadioButtonsPropTypes> = ({
  name,
  options,
  row = false,
  disabled = false,
  onChange,
  setValue,
  savedValue,
}: IFormRadioButtonsPropTypes) => {
  const { control } = useFormContext();
  const key = `${savedValue}`;

  useEffect(() => {
    if (savedValue) {
      setValue("sendTo", savedValue);
    }
  }, [savedValue]);

  const handleChange = (e): void => {
    if (onChange) {
      onChange(Number(e?.target.value));
    }
  };

  const radioButtons = options.map((option, i) => (
    <Tooltip
      key={i}
      title={(option.disabled && option.tooltip) || ""}
      placement="top"
      sx={{
        display: option.hidden ? "none" : "inline-flex",
      }}
      data-testid={
        EFormAssignmentNotificationType[
          option?.value as keyof typeof EFormAssignmentNotificationType
        ]
      }
    >
      <FormControlLabel
        sx={{
          padding: "2px 0",
          marginLeft: 0,
          display: option.hidden ? "none" : "inline-flex",
        }}
        value={option.value}
        label={
          typeof option.label === "string" ? (
            <Typography
              variant="body2"
              pl={"3px"}
              sx={{
                color:
                  option.disabled || disabled
                    ? "text.disabled"
                    : "text.primary",
              }}
            >
              {option.label}
            </Typography>
          ) : (
            option.label
          )
        }
        control={
          <SRadioButton
            data-testid={`radioButton-${
              EFormAssignmentNotificationType[
                option?.value as keyof typeof EFormAssignmentNotificationType
              ]
            }`}
            size="small"
          />
        }
        disabled={option.disabled || disabled}
      />
    </Tooltip>
  ));
  return (
    <Box key={key}>
      <FormControl component="fieldset">
        <Controller
          name={name}
          control={control}
          render={({ fieldState: { error } }) => (
            <>
              <RadioGroup
                value={savedValue}
                onChange={handleChange}
                row={row}
                data-testid="radioGroup"
              >
                {radioButtons}
                {error && <ErrorHelperText>{error?.message}</ErrorHelperText>}
              </RadioGroup>
            </>
          )}
        />
      </FormControl>
    </Box>
  );
};
