import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { apiHeaders } from "./apiHeaders";
import { sec } from "./security";
import { RootState } from "../../store";
import { IContentType } from "../../types/settings/formBuilder/IContentType.interface";

const getBaseQuery = (baseUrl, contentType) =>
  fetchBaseQuery({
    baseUrl: baseUrl,
    credentials: "include",
    prepareHeaders: async (headers, { getState }) => {
      const getAccessTokenSilently = sec.getAccessTokenSilently();
      const token = await getAccessTokenSilently();
      const guid = (getState() as RootState).appState.api.practiceGuid;
      const sessionId = (getState() as RootState).appState.sessionId;
      const headerInfo = {
        auth0Token: token,
        practiceGuid: guid,
      };
      apiHeaders(headers, headerInfo, contentType, sessionId);
      return headers;
    },
  });
//TODO: Add the correct type for the args and add retryCount and retryDelay to the args retryCount: 3, retryDelay: 1000
export const baseQueryWithReAuth =
  (
    baseURL: string,
    contentType?: IContentType
  ): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> =>
  async (args, api, extraOptions) => {
    const baseQuery = getBaseQuery(baseURL, contentType);
    const result = await baseQuery(args, api, extraOptions);

    if (!result.error) return result;

    if (
      result.error &&
      (result.error.status == 403 || result.error.status == 401)
    ) {
      return baseQuery(args, api, extraOptions);
    } else {
      return result;
    }
  };
