export const getBaseRoute = (route: string) => {
  if (!route) return "";
  if (route.startsWith("/TextMessages")) {
    return "/TextMessages";
  }
  if (route.startsWith("/dashboard")) {
    return "/dashboard";
  }
  if (
    route.startsWith(
      "/practiceSettings/patientReminders/textTemplates/compose"
    ) ||
    route.startsWith("/textTemplates/compose")
  ) {
    return "/textTemplates";
  }
  if (
    route.startsWith("/patientProfile") ||
    route.startsWith("/patientFinder") ||
    route.startsWith("/preferences")
  ) {
    return "/patientFinder";
  }
  if (route.startsWith("/document-manager/")) {
    const parts = route.split("/");
    if (
      parts.length &&
      (parts.includes("image") ||
        parts.includes("preview") ||
        parts.includes("signature"))
    ) {
      return route;
    } else {
      return "/document-manager";
    }
  }
  if (
    route.startsWith("/inviteNewUser") ||
    route.startsWith("/editUser") ||
    route.startsWith("/users")
  ) {
    return "/users";
  }
  if (route.startsWith("/emailCampaigns")) {
    return "/emailCampaigns";
  }
  if (route.startsWith("/practiceSettings")) return "/practiceSettings";
  return route.split("?")[0];
};

// this one is without dashboard [YAP-6716]
export const getBaseRouteNoDashboard = (route: string) => {
  if (!route) return "";
  if (route.startsWith("/TextMessages")) {
    return "/TextMessages";
  }
  if (
    route.startsWith(
      "/practiceSettings/patientReminders/textTemplates/compose"
    ) ||
    route.startsWith("/textTemplates/compose")
  ) {
    return "/textTemplates";
  }
  if (
    route.startsWith("/patientProfile") ||
    route.startsWith("/patientFinder") ||
    route.startsWith("/preferences")
  ) {
    return "/patientFinder";
  }
  if (route.startsWith("/document-manager/")) {
    const parts = route.split("/");
    if (
      parts.length &&
      (parts.includes("image") ||
        parts.includes("preview") ||
        parts.includes("signature"))
    ) {
      return route;
    } else {
      return "/document-manager";
    }
  }
  if (
    route.startsWith("/inviteNewUser") ||
    route.startsWith("/editUser") ||
    route.startsWith("/users")
  ) {
    return "/users";
  }
  if (route.startsWith("/emailCampaigns")) {
    return "/emailCampaigns";
  }
  if (route.startsWith("/practiceSettings")) return "/practiceSettings";
  return route.split("?")[0];
};
